import React, { useState } from "react";
import Layout from "../../components/Layout";
import SectionContent from "../../components/Sections/SectionContent";
import Row from "../../components/Lotteries/Row";

const VIEW_ITEMS_COUNT = 10;

const Homepage = ({
    pageContext: {
        lotteries,
        isDisabledMetaIndex = false
    }
}) => {
    const [viewCount, setViewCount] = useState(VIEW_ITEMS_COUNT);
    const [showMore, setShowMore] = useState(false);

    const viewMoreItemsHandler = () => setViewCount(viewCount + VIEW_ITEMS_COUNT);
    const showMoreTextHandler = () => setShowMore(true);

    return (
        <Layout isDisabledMetaIndex={isDisabledMetaIndex}>
            <SectionContent
                title='Your most comprehensive source of lotto results.'
                titleSize={'bigger'}
            >
                <p className={`show-more-text ${showMore ? 'active' : ''}`}>
                    From the most popular lotteries in the world such as the US Powerball and Mega Millions to lotteries that are only enjoyed by the aficionados of the gambling community, this page offers all the lottery results you may need! With over 160 lotteries to choose from, you are bound to find a lottery that will suit your play style and your ambitions. Whether you like the feeling of ‘almost being a millionaire’ that the high-odds lotteries give you or the constant adrenaline rush of the fast-paced lotteries, on our website you will find exactly what you’re looking for!
                </p>
                {
                    !showMore && <button type='button' className={`show-more-btn`} onClick={showMoreTextHandler}>Read more >></button>
                }
            </SectionContent>
            <SectionContent
                title='The latest Results from around the world.'
                titleAlign={'left'}
            >
                <div className={`lotteries-results__container lotteries-results__link-active`}>
                    {
                        lotteries && lotteries.slice(0, viewCount).map(lotteryDraw => (
                            <Row
                                key={`${lotteryDraw.id}-${lotteryDraw.draw_id}`}
                                lottery={lotteryDraw}
                                isLinkToSinglePageActive={true}
                            />
                        ))
                    }
                </div>
                {
                    lotteries && viewCount < lotteries.length &&
                    <button
                        type='button'
                        className="lotteries-results__button"
                        onClick={viewMoreItemsHandler}
                    >
                        Load more
                    </button>
                }
            </SectionContent>
            <SectionContent title='We have over 160 draws from around the world.'>
                <p className={'text-center'}>
                    Are you interested in historical results or unable to view our website for some reason? Just send us a list of draws that are of interest to you, and we’ll provide you with all the necessary information through WhatsApp.
                    <a href="https://wa.me/27661800842/?text=help" className='btn-link'> Send me Results</a>
                </p>
            </SectionContent>
        </Layout>
    );
};

export default Homepage;
